<template>
    <section class="Tactile wrapper" id="article">
      <h1>
        <span>2</span>
        {{ $t("home.tactile.title") }}
      </h1>
      <article>
        <p>
          {{ $t("home.tactile.description0") }}
        </p>
        <p>
          {{ $t("home.tactile.description1") }}
        </p>
        <p>
          {{ $t("home.tactile.description2") }}
        </p>
      </article>
    </section>
</template>

<script>
export default {
  name: "Tactile",
  components: {},
  props: [],
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() {
  },
}
</script>

<style lang="scss">
/*  Tactile  */
.Tactile {
  width: 100%;
  height: auto;
}
</style>